import React, { useRef } from "react";
import { BsArrowRight } from "react-icons/bs";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
function Loginform() {
    const form = useRef() as any;
    const sendEmail = (e: any) => {
        e.preventDefault();
        emailjs
            .sendForm(
                "service_a7e2oqf",
                "template_pbi3l93",
                form.current,
                "user_eH22lGwqsG97Q9atyNFC3"
            )
            .then(
                (result) => {
                    Swal.fire({
                        icon: "success",
                        title: "Success",
                        text: "Email Sent Successfully. You will be reached out within 24 hours.",
                    });
                    console.log(result.text);
                    form.current.reset()
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };
    return (
        <div className="px-4 lg:pr-4">
            <form
                className="w-full lg:max-w-[750px] flex flex-wrap justify-between items-start"
                ref={form}
                onSubmit={sendEmail}
            >
                <div className="w-full justify-between md:flex md:space-x-6 space-y-7 md:space-y-0 xl:space-x-6">
                    <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        required
                        className=" placeholder:text-[15px] placeholder:text-[#3e3e3e] text-[14px] focus:border focus:border-red-600 text-black px-6 outline-none rounded-lg w-full md:basis-1/2 h-[55px] shadow-[_3px_2px_3px_rgba(170,170,170)]"
                    />
                    <input
                        type="text"
                        placeholder="Phone Number"
                        name="phone"
                        required
                        className="placeholder:text-[15px] placeholder:text-[#3e3e3e] text-[14px] focus:border focus:border-red-600 text-black px-6 outline-none rounded-lg w-full md:basis-1/2 h-[55px] shadow-[_3px_2px_3px_rgba(170,170,170)]"
                    />
                </div>

                <div className=" w-full md:basis-1/2 md:pr-3">
                    <input
                        type="email"
                        placeholder="Email address"
                        required
                        name="email"
                        className="placeholder:text-[15px] placeholder:text-[#3e3e3e] text-[14px] focus:border focus:border-red-600 text-black px-6 my-6 outline-none rounded-lg w-full md:basis-1/2 h-[55px] shadow-[_3px_2px_3px_rgba(170,170,170)]"
                    />
                </div>

                <textarea
                    placeholder="What do you need help with?"
                    required
                    name="message"
                    className="placeholder:text-[15px] placeholder:text-[#3e3e3e] text-[14px] focus:border focus:border-red-600 text-black px-6 py-4 outline-none rounded-lg w-full h-[220px] shadow-[_3px_2px_3px_rgba(170,170,170)]"
                />

                <button className="btn-style-one theme-btn flex justify-between mt-5 items-center text-white h-[58px] text-[15px] w-[140px]">
                    <span className="btn-wrap">
                        <span className="text-one">submit</span>
                        <span className="text-two">submit</span>
                    </span>
                </button>
            </form>
        </div>
    );
}

export default Loginform;
