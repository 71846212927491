import React, { FC,memo } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
const Faqitem: FC<{
    ques?: string;
    ans?: string;
    active:boolean;
    changeData:(id:string)=>void
    id:string
  }> = memo(({ques,ans,active,changeData,id}) => {
  return (
    <div className=" w-full FAQsPanel">
      <div className=" flex justify-between border-b-[1px] py-4 " onClick={() => changeData(id)}>
        <h3 className=" text-lg sm:text-xl font-semibold md:font-bold">{ques}</h3>
        <button className=" cursor-pointer">
          {active ? <FaMinus /> : <FaPlus />}{" "}
        </button>
      </div>
        <p className={`py-4 text-base text-[#817E7E] font-semibold collapsable ${active?"active":"nonactive"}`}>
          {ans}
        </p>
    </div>
  );
});

export default Faqitem;
