import React from "react";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Container from "./Utilities/Container";
const QuilityPrinting = () => {
  const data = [10, 18, 22, 15, 31, 29, 20];
  return (
    <Container>
      <div className="printing-section pb-[100px]">
        <div className="title-box text-4xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-8xl">
          Quality. <span>Printing</span>. Solutions
          <i>Quality. Printing. Solutions</i>
        </div>
        <Swiper
          // slidesPerView={2}
          // spaceBetween={30}
          navigation={true}
          loop={true}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          modules={[Navigation]}
          className="mySwiper"
        >
          {data.map((obj) => {
            return (
              <SwiperSlide key={obj} className="printing-section">
                <div className="flex flex-wrap">
                  <div className="md:flex-1 relative w-full">
                    <div className="inner-box">
                      <div className="px-[2px]">
                        <div className="bg-white text-black px-4 py-3 inline-block font-semibold absolute top-3 left-3">
                          Before Service
                        </div>
                          <img
                            src={`./assets/images/Before After/${obj}B.jpg`}
                            alt=""
                          />
                      </div>
                    </div>
                  </div>
                  <div className="md:flex-1 relative w-full">
                    <div className="inner-box">
                      <div className="px-[2px]">
                        <div className="bg-white text-black px-4 py-3 inline-block font-semibold absolute top-3 left-3">
                          After Service
                        </div>
                          <img
                            src={`./assets/images/Before After/${obj}A.jpg`}
                            alt=""
                          />
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </Container>
  );
};

export default QuilityPrinting;
