import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Navigation } from "swiper";

const OurWork = () => {
  const data = [
    "images/one.png",
    "images/two.png",
    "images/three.png",
    "images/four.png",
    "images/six.png",
    "images/seven.png",
    "images/eight.png",
    "images/14.png",
    "images/Before After/31A.jpg",
  ];
  return (
    <div className="project-block">
      <div className="relative text-2xl md:text-3xl lg:text-4xl font-medium flex justify-center mb-8 title-box text-[#4e1e10]">
        <h2>Our Work</h2>
      </div>
      <Swiper
        slidesPerView={1}
        // centeredSlides={true}
        spaceBetween={20}
        grabCursor={true}
        // pagination={{
        //   clickable: true,
        // }}
        navigation={true}
        loop={true}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        modules={[Navigation]}
        breakpoints={{
          640: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        className="mySwiper"
      >
        {data.map((obj, i) => {
          return (
            <SwiperSlide key={i}>
              <div className="project-block">
                <div className="inner-box">
                  <div className="image">
                    <img src={`./assets/${obj}`} alt="" />
                    <div className="post-number">{i + 1}</div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default OurWork;
