import React from "react";
import Navbar from "./Navbar";
import FixedNavbar from "./Utilities/FixedNavbar";
import Sidebar from "./Utilities/Sidebar";

const NavBars = () => {
  return (
    <div>
      <Navbar />
      <FixedNavbar />
      <Sidebar />
    </div>
  );
};

export default NavBars;
