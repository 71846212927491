import React, { memo } from 'react'
import {FaFacebook,FaInstagram} from 'react-icons/fa'
import { Link } from 'react-router-dom';
const Sidebar = memo(() => {
  return (
    <div className='relative hidden xl:block'>
        <ul className=' absolute font-medium -left-[530px] -top-12 text-[20px] text-black flex space-x-9 m-80 -rotate-90'>
            <li className='cursor-pointer hover:text-[#C34C27]'>
              <FaFacebook/>
               </li>
            <Link to="https://www.instagram.com/printstationorg"><li className='cursor-pointer hover:text-[#C34C27]'>
              <FaInstagram/>
            </li></Link>
            <li className='cursor-pointer hover:text-[#C34C27] pl-32'>info@theprintstation.org</li>
        </ul>
    </div>
  )
})

export default Sidebar