import React, { FC, memo } from "react";
import { RxCross2 } from "react-icons/rx";
import logo from "../../assets/images/logoo.png";
import { Link, useLocation } from "react-router-dom";
const MobileNavlist: FC<{ isVisible: boolean; toggleVisibility: any }> = memo(
  ({ isVisible, toggleVisibility }) => {
    return (
      <div
        className={`absolute ${
          isVisible ? "left-0 w-[300px]" : "left-[-100%]"
        } z-50 block top-0 md:hidden bg-[#F5F5F5] transition-all h-screen duration-500 ease-in`}
      >
        <div className=" justify-center flex py-10">
          <Link to="/">
            <img src={logo} alt="WabbitCard" className=" w-36" />
          </Link>
        </div>
        <ul className=" pl-5 text-base font-medium cursor-pointer">
          <li
            className={`${
              useLocation()?.pathname === "/"
                ? " text-[#DD1D26]"
                : "text-black hover:text-[#DD1D26]"
            } py-5`}
          >
            <Link to="/">Home</Link>
          </li>
          <li
            className={`${
              useLocation()?.pathname === "/contact"
                ? " text-[#DD1D26]"
                : "text-black hover:text-[#DD1D26]"
            }`}
          >
            <Link to="/contact">Contact Us</Link>
          </li>
        </ul>
        <button
          className="text-[#F5F5F5] absolute right-3 top-4 bg-black text-[28px] flex  cursor-pointer"
          type="button"
          onClick={toggleVisibility}
        >
          <RxCross2 />
        </button>
      </div>
    );
  }
);

export default MobileNavlist;
