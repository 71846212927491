import React, { FC, memo } from "react";
const DoitCard: FC<{
  img?: string;
  head?: string;
  text?: string;
  num?: string;
  arrow?: string;
  data?: any;
}> = memo(({ img, head, text, num, arrow, data }) => {
  return (
    <div className=" w-full py-5 md:py-0 md:w-1/2 lg:w-1/3 text-center mt-0 md:mt-10 lg:mt-0">
      <div className=" relative">
        <img src={img} alt="" className=" mx-auto w-[54px]" />{" "}
      </div>
      <div className="last relative">
        <h1 className={`text-lg md:text-[22px] font-medium pt-4 pb-[10px] ${data? "hover:text-[#C34C27]" : " "}`}>
          {head}
        </h1>
        <span className=" absolute top-[22px] -right-3 hidden lg:block">
          <img src={arrow} alt="" className="w-10 contrast-50" />
        </span>{" "}
      </div>
      <p className="text-base font-normal text-[#817E7E]">{text}</p>
    </div>
  );
});

export default DoitCard;
