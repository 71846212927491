import React, { memo, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logoo.png";
import Navlist from "./Utilities/Navlist";
import {BiMenu} from "react-icons/bi"
import MobileNavlist from "./Utilities/MobileNavlist";

const Navbar = memo(() => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };
  return (
    <div id="nav">
      <div className="flex justify-between p-4 items-center max-w-[1760px] mx-auto">
        <div>
          <Link to="/">
            <img src={logo} alt="WabbitCard" className=" w-24" />
          </Link>
        </div>
        <Navlist/>
        <div className="flex md:hidden items-center justify-center">
          <button
            className=" flex ml-auto  z-10 cursor-pointer text-3xl px-3 border border-solid border-transparent rounded bg-transparent lg:hidden outline-none focus:outline-none"
            type="button"
            onClick={toggleVisibility}
          >
            <BiMenu />
          </button>
        </div>
      </div>
      <MobileNavlist
        isVisible={isVisible}
        toggleVisibility={toggleVisibility}
      />
    </div>
  );
});

export default Navbar;
