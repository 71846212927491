import React, { memo } from "react";
import Container from "./Utilities/Container";

const Footer = memo(() => {
  return (
    <div className="bg-black pt-4 lg:px-20 pb-12">
      <Container>
        <div className=" space-y-4 sm:space-y-0 flex flex-col items-center sm:flex-row sm:justify-between basis-full">
          <p className=" text-white text-sm font-normal">3101 N. Central Ave, Ste 183 #3416 Phoenix, Arizona 85012</p>
          <p className=" text-white text-sm font-normal">(310)-500-7577</p>
        </div>
      </Container>
    </div>
  );
});

export default Footer;
