import {useState,useEffect} from 'react'
import Doit from "../components/Doit";
import Faq from "../components/Faq";
import Footer from "../components/Footer";
import Header from "../components/Header";
import OurServices from "../components/OurServices";
import OurWork from "../components/OurWork";
import Preloader from "../components/Preloader";
import QuilityPrinting from "../components/QuilityPrinting";
import ScrollToTop from '../components/Utilities/ScrollToTop';
const Home = () => {
  const [preTimer,setPreTimer]=useState(true)
  useEffect(() => {
    const PreloaderTimer=setTimeout(() => {
      setPreTimer(false)
    }, 2000);
    return () => {
      clearTimeout(PreloaderTimer)
    };
  }, [])
  return (
    <div className={`bg-[#F9F7F5] ${preTimer&&'h-screen overflow-hidden'}`}>
    <ScrollToTop/>
      {preTimer&&<Preloader/>}
      <Header/>
      <QuilityPrinting/>
      <OurServices/>
      <OurWork/>
      <Doit/>
      <Faq/>
      <Footer/>
    </div>
  );
};

export default Home;
