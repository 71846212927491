import React, { memo, useState } from "react";
import Container from "./Utilities/Container";
import Faqitem from "./Utilities/Faqitem";

const Faq = memo(() => {
  const [ChoseData, setChoseData] = useState([
    {
      id: "Q1",
      ques: "Where are you located?",
      ans: "We have our office location currently in Phoenix, Arizona, but we serve businesses all across America.",
      active: true,
    },
    {
      id: "Q2",
      ques: "Can I see work samples in person?",
      ans: "Yes, feel free to set up an appointment and one of our agents will visit your business with work samples.",
      active: false,
    },
    {
      id: "Q3",
      ques: "How long does it take to process orders?",
      ans: "Due to overwhelming demand, the current processing time for printing orders is 4-5 weeks.",
      active: false,
    },
    {
      id: "Q4",
      ques: "Can I have the items delivered to my business?",
      ans: "Absolutely, we offer door to door delivery services.",
      active: false,
    },
  ]);
  const changeData = (id: string) => {
    setChoseData(ChoseData.map((obj: any) => {
      if (obj.id === id) {
        return {
          ...obj,
          active: true,
        };
      }
      return {
        ...obj,
        active: false,
      };
    }))
  };
  return (
    <div className=" sm:px-12 md:px-24 lg:px-36 xl:px-48 pb-[100px]">
      <div className="relative text-2xl md:text-3xl lg:text-4xl font-medium flex justify-center mb-6 title-box text-[#4e1e10]">
        <h2>FAQs</h2>
      </div>
      <Container>
        <div className="flex flex-col md:flex-row md:flex-wrap md:justify-evenly ">
          {ChoseData.map((obj) => (
            <Faqitem
              key={obj.id}
              id={obj.id}
              changeData={changeData}
              ques={obj.ques}
              ans={obj.ans}
              active={obj.active}
            />
          ))}
        </div>
      </Container>
    </div>
  );
});

export default Faq;
