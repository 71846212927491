import React, { memo } from "react";
import { BsArrowRight } from "react-icons/bs";
import {Link, useLocation} from 'react-router-dom'
const Navlist = memo(() => {
  
  return (
    <div className=" hidden md:block">
      <ul className="flex items-center space-x-10 text-base font-medium cursor-pointer">
        <li className={`${useLocation()?.pathname==="/"?" text-[#DD1D26]":"text-black hover:text-[#DD1D26]"}`}>
          <Link to="/">
          Home
          </Link>
        </li>
        <li className={`${useLocation()?.pathname==="/contact"?" text-[#DD1D26]":"text-black hover:text-[#DD1D26]"}`}>
          <Link to="/contact">Contact Us</Link>
        </li>
        <li className=" text-[#A5ABAB] hidden xl:block">
          Phone <br />{" "}
          <span className=" text-sm text-[#DD1D26]">(310)-500-7577</span>
        </li>
        <li className=" hidden lg:block ">
        <Link to="/contact">
          <button className="btn-style-one theme-btn">
            <span className="btn-wrap">
              <span className="text-one">Get Quote</span>
              <span className="text-two">Get Quote</span>
            </span>
          </button>
          </Link> 
        </li>
      </ul>
    </div>
  );
});

export default Navlist;
