import React, {FC, memo } from 'react'

const ServiceCard: FC<{
    img?: string;
    head?: string;
    text?: string;
  }>  = memo(({ img, head, text}) => {
  return (
    <div className='py-5 w-full md:w-72 lg:w-[300px]'>
        <img src={img} alt="" className=' w-[54px] hover:rotate-[360deg] duration-700'/>
        <h1 className=' text-lg font-medium py-4 hover:text-[#C34C27]'>{head}</h1>
        <p className='text-base font-normal text-[#817E7E]'>{text}</p>
    </div>
  )
})

export default ServiceCard