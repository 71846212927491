import React, { memo } from "react";
import Container from "./Utilities/Container";
import DoitCard from "./Utilities/DoitCard";
import consult from "../assets/images/icons/consulting.png"
import quality from "../assets/images/icons/quality.png"
import delivered from "../assets/images/icons/delivered.png"
import arrow from "..//assets/images/arrow.png"
const Doit = memo(() => {
  const ChoseData = [
    {
      id: "1",
      img: consult,
      head: "Free Consultation",
      text: "You set up free in-person consultation",
      num: "01",
      arrow:arrow ,
    },
    {
      id: "Card2",
      img: quality,
      head: "Quality Check",
      text: "You get a test sample of your order for quality check.",
      num: "02",
      arrow:arrow ,
    },
    {
      id: "Card3",
      img: delivered,
      head: "Delivered",
      text: "Your custom packages are delivered to your business.",
      num: "03",
      data:true,
    },
  ];
  return (
    <Container>
      <div className=" pb-[70px]">
        <div className="relative text-2xl md:text-3xl lg:text-4xl font-medium flex justify-center mb-4 title-box text-[#4e1e10]">
          <h2>How we do it?</h2>
        </div>
        <div className=" lg:pt-10 flex md:flex-wrap justify-center md:justify-between lg:justify-around flex-col lg:flex-row items-center">
          {ChoseData.map((obj) => (
            <DoitCard
              key={obj.id}
              text={obj.text}
              img={obj.img}
              head={obj.head}
              num={obj.num}
              arrow={obj.arrow}
              data={obj.data}
            />
          ))}
        </div>
      </div>
    </Container>
  );
});

export default Doit;
